$breakpoints: (
  "medium": (
    min-width: 500px,
  ),
  "large": (
    min-width: 800px,
  ),
  "huge": (
    min-width: 1000px,
  ),
);

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(24, 23, 48);
  color:aliceblue;
}

.Soluciones {
  border: 3px solid black;
  border-radius: 0.375rem;
  
  width: 90%;
  margin: 0.5rem auto;
  padding: 0.5rem;

  @include respond-to('large') { 
    width: 800px;
  }
}

.flex-table {
  display: flex;

  flex-flow: row nowrap;
  margin: 2rem 0;

  font-size: smaller;

  @include respond-to('medium') {
    font-size: normal;
  }

  .fixed {
    width: 175px;

    @include respond-to('medium') {
      width: 275px;
    }

    table {
      width: 175px;
      height: 100%;

      @include respond-to('medium') {
        width: 275px;
      }
    }

    select, input {
      font-size: x-small;
      padding: 3px;

      @include respond-to('medium') {
        font-size: normal;
      }
    }

    input {
      width: 30px;

      @include respond-to('medium') {
        width: 50px;
      }
    }
  }

  .data {
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;
    vertical-align: top;

    @include respond-to('large') {
      overflow-x: hidden;
    }

    table {
      height: 100%;
    }
  }
}

.boton {
  text-align: center;

  // @include respond-to('medium')
}

.flex-td {
  display: flex;
  flex-flow: row nowrap;
}